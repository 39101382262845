<template>
    <DashboardContainer>
        <callHistory/>
    </DashboardContainer>
</template>

<script>
import DashboardContainer from '../containers/DashboardContainer'
import callHistory from '../components/callHistory/callHistory.vue'

export default {
    name: 'attribution',
    beforeCreate: function () {
        var auth = this.$storage.get('auth')
        if (!auth) {
            this.$router.push('/login')
        }else{
            if(!auth.token){
                this.$router.push('/login')
            }
        }
    },
    components: {
        DashboardContainer,
        callHistory
    }
}
</script>